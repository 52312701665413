import React, { useContext, useState } from 'react';
//context
import { GlobalContext } from 'context';
//styles
import styled from 'styled-components';
import vars from 'components/styles/varss';
import { rem, between } from 'polished';
//components
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
//animations
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { HeaderFadeUp, HeaderFadeUp2, HeaderUnderline } from 'components/animations';

//styled
const HelloWrap = styled.section`
  position: relative;
  padding-top: ${rem('330px')};
  padding-bottom: ${rem('290px')};
  background-color: #fff;
  color: #000;
  text-align: center;
  @media (max-width: ${vars.media.lgMax}) {
    padding-top: ${between('130px', '330px')};
    padding-bottom: ${between('150px', '290px')};
  }
  a {
    display: block;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    line-height: 1;
    letter-spacing: -0.56px;
    font-size: ${rem('90px')};
    color: #000;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${between('60px', '90px')};
    }
    span {
      display: inline-block;
      transition: ${vars.transitions.hover1};
    }
    &:hover {
      span {
        transform: translateX(10px);
      }
    }
  }
  h3 {
    color: #000;
    border-color: #000;
  }
  .view {
    position: relative;
    top: 40vh;
  }
`;

const Hello = () => {

  //use Context
  const [context, setContext] = useContext(GlobalContext);

  const enter = () => {
    setContext({
      ...context,
      navDark: true
    });
  };

  const leave = () => {
    setContext({
      ...context,
      navDark: false
    });
  };

  //animate state
  const [animState, setAnimState] = useState(false);

  const enterAnim = () => {
    setAnimState(true)
  };

  return (
    <React.Fragment>
      <Waypoint onEnter={enterAnim} topffset="20%" />
      <Waypoint onEnter={enter} onLeave={leave} bottomOffset="90%">
        <HelloWrap>
          <Container>
            <motion.div
              initial={false}
              variants={HeaderFadeUp}
              animate={animState ? 'animate' : 'initial'}>
              <a href="mailto:info@integral.im">Let’s connect and shape what comes next <span>→</span></a>
            </motion.div>
            <div className="overflow">
              <motion.div
                initial={false}
                variants={HeaderFadeUp2}
                animate={animState ? 'animate' : 'initial'}>
                <Heading as="h3" underlined>
                  Say Hello
                  <motion.div
                    className="underline black"
                    variants={HeaderUnderline}
                    animate={animState ? 'animate' : 'initial'}
                  />
                </Heading>
              </motion.div>
            </div>
          </Container>
        </HelloWrap>
      </Waypoint>
    </React.Fragment>
  );
};

export default Hello;
import React, { useState } from 'react';
//gatsby
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//styles
import styled from 'styled-components';
//animations
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { WorkFadeUp } from 'components/animations';

const WorkImageWrap = styled.div`
  position: relative;
`;

const WorkImage = ({ file }) => {

  //state
  const [animState, setAnimState] = useState(false);

  const enter = () => {
    setAnimState(true);
  };

  return(
    <Waypoint onEnter={enter} bottomOffset="20%">
      <WorkImageWrap>
        <motion.div
          initial={false}
          variants={WorkFadeUp}
          animate={animState ? 'animate' : 'initial'}>
          <GatsbyImage image={getImage(file)} alt="Work" />
        </motion.div>
      </WorkImageWrap>
    </Waypoint>
  );
};

export default WorkImage;
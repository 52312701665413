import React, { useState } from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
//styles
import styled from 'styled-components';
import vars from 'components/styles/varss';
import { rem, between } from 'polished';
//components
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
import WorkImage from 'components/ui/workImage';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//animations
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { HeaderFadeUp, HeaderFadeUp2, HeaderUnderline } from 'components/animations';

//styled
const OurWorkWrap = styled.section`
  position: relative;
  padding-top: ${rem('300px')};
  padding-bottom: ${rem('200px')};
  background-color: #000;
`;

const OurWorkHeader = styled.div`
  text-align: center;
  margin-bottom: ${rem('200px')};
  @media (max-width: ${vars.media.lgMax}) {
    margin-bottom: ${between('120px', '200px')};
  }
  h2 {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
`;

const OurWorkImage1 = styled.div`
  margin-bottom: ${rem('230px')};
  @media (max-width: ${vars.media.lgMax}) {
    margin-bottom: ${between('30px', '230px')};
  }
`;

const OurWorkImage2 = styled.div`
  text-align: center;
  .gatsby-image-wrapper {
    display: inline-block;
  }
`;

const OurWorkImage3 = styled.div`
  text-align: right;
  margin-top: ${rem('160px')};
  margin-bottom: ${rem('160px')};
  @media (max-width: ${vars.media.lgMax}) {
    margin-top: ${between('50px', '160px')};
    margin-bottom: ${between('50px', '160px')};
  }
  .gatsby-image-wrapper {
    display: inline-block;
  }
`;

const OurWorkImage4 = styled.div`
  text-align: center;
  .gatsby-image-wrapper {
    display: inline-block;
  }
`;

const OurWork = () => {

  const data = useStaticQuery(graphql`
    query {
      Work1: file(relativePath: { eq: "works1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100)
        }
      }
      Work2: file(relativePath: { eq: "works2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 613, quality: 100)
        }
      }
      Work3: file(relativePath: { eq: "works3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 490, quality: 100)
        }
      }
      Work4: file(relativePath: { eq: "works4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 490, quality: 100)
        }
      }
    }
  `);

  //state
  const [animState, setAnimState] = useState(false);

  const enter = () => {
    setAnimState(true);
  };

  return (
    <OurWorkWrap>
      <Container>
        <Waypoint onEnter={enter} bottomOffset="20%">
          <OurWorkHeader>
            <motion.div
              initial={false}
              variants={HeaderFadeUp}
              animate={animState ? 'animate' : 'initial'}>
              <Heading as="h2" heading2>We create thoughtful digital fashion solutions, leveraging strategy, design & technology to  connect brands with their audiences.</Heading>
            </motion.div>
            <div className="overflow">
              <motion.div
                initial={false}
                variants={HeaderFadeUp2}
                animate={animState ? 'animate' : 'initial'}>
                <Heading as="h3" underlined>
                  Our Work
                  <motion.div
                    className="underline"
                    variants={HeaderUnderline}
                    animate={animState ? 'animate' : 'initial'}
                  />
                </Heading>
              </motion.div>
            </div>
          </OurWorkHeader>
        </Waypoint>
        <Row className="row">
          <Col md={6}>
            <OurWorkImage1>
              <WorkImage file={data.Work1} />
            </OurWorkImage1>
            <OurWorkImage2>
              <WorkImage file={data.Work2} />
            </OurWorkImage2>
          </Col>
          <Col md={6}>
            <OurWorkImage3>
              <WorkImage file={data.Work3} />
            </OurWorkImage3>
            <OurWorkImage4>
              <WorkImage file={data.Work4} />
            </OurWorkImage4>
          </Col>
        </Row>
      </Container>
    </OurWorkWrap>
  );
};

export default OurWork;
import React from "react";
//styles
import styled, { css } from 'styled-components';
//animation
import { motion, AnimatePresence } from "framer-motion";
import vars from 'components/styles/varss';
import { rem } from 'polished';

const AccordionWrap = styled.div`
  position: relative;
`;

const AccordionHeader = styled(motion.div)`
  cursor: pointer;
  font-size: ${rem('60px')};
  line-height: 1.25;
  transition: ${vars.transitions.hover1};
  color: #333;
  //active
  ${props => props.active && css`
    color: #fff;
  `}
`;

const AccordionContent = styled(motion.div)`
  overflow: hidden;
`;

const Accordion = ({ i, expanded, setExpanded, title, services }) => {
  const isOpen = i === expanded;
  return (
    <AccordionWrap>
      <AccordionHeader
        active={isOpen}
        onClick={() => setExpanded(isOpen ? false : i)}>
        {title}
      </AccordionHeader>
      <AnimatePresence initial={false}>
        {isOpen && (
          <AccordionContent
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 }
              }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            >
            <ul>
              {services.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </AccordionContent>
        )}
      </AnimatePresence>
    </AccordionWrap>
  );
};

export default Accordion;
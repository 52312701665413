import React, { useState } from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//styles
import styled, { keyframes } from 'styled-components';
import { between, rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//animations
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { HeaderFadeUp } from 'components/animations';

//styled
const SubscribeWrap = styled.section`
  position: relative;
  background-color: #000;
  padding-top: ${rem('330px')};
  padding-bottom: ${rem('300px')};
  @media (max-width: ${vars.media.lgMax}) {
    padding-top: ${between('150px', '330px')};
    padding-bottom: ${between('150px', '330px')};
  }
`;

const SubscribeHeader = styled.div`
  text-align: center;
  margin-bottom: ${rem('330px')};
  @media (max-width: ${vars.media.lgMax}) {
    margin-bottom: ${between('100px', '330px')};
  }
  h2 {
    max-width: 6000px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SubscribeForm = styled.div`
  h3 {
    margin-bottom: ${rem('120px')};
    @media (max-width: ${vars.media.lgMax}) {
      margin-bottom: ${between('50px', '120px')};
    }
  }
  .subscribe-box {
    display: block;
    width: 100%;
    max-width: 443px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    font-size: ${rem('30px')};
    height: 50px;
    color: #fff;
    ::placeholder {
      color: #fff;
    }
    &:focus {
      outline: none;
    }
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loop = styled.div`
  position: relative;
  max-width: 540px;
  @media (max-width: ${vars.media.smMax}) {
    display: none;
  }
  p {
    font-weight: 500px;
    font-size: ${rem('40px')};
    line-height: 1;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${between('30px', '40px')};
    }
  }
  .text {
    fill: #fff;
  }
  .loop-wrap {
    transform: scale(2.2);
    position: absolute;
    margin: 0;
    top: 15%;
    left: 30%;
    z-index: 2;
  }
  .loop-circle {
    animation: ${rotate} 15s linear infinite;
  }
`;

const Subscribe = () => {

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "loop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 540, quality: 100)
        }
      }
    }
  `);

  //animate state
  const [animState, setAnimState] = useState(false);

  const enter = () => {
    setAnimState(true)
  };

  return (
    <SubscribeWrap>
      <Container>
        <Waypoint onEnter={enter} bottomOffset="20%">
          <SubscribeHeader>
            <motion.div
              initial={false}
              variants={HeaderFadeUp}
              animate={animState ? 'animate' : 'initial'}>
              <Heading as="h2" heading2>The fashion industry<br />is ready for a make-over</Heading>
            </motion.div>
          </SubscribeHeader>
        </Waypoint>
        <Row className="row">
          <Col md={6}>
            <SubscribeForm>
              <Heading as="h3" heading3>Stay In The Loop</Heading>
              <input type="text" placeholder="Subscribe to our newsletter	→" className="subscribe-box" />
            </SubscribeForm>
          </Col>
          <Col md={6}>
            <Loop>
              <div className="loop-wrap">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width={300}
                  height={300}
                  viewBox="0 0 300 300"
                  className="loop-circle"
                >
                  <defs>
                    <path id="prefix__a" d="M90 150a60 60 0 01120 0 60 60 0 01-120 0" />
                  </defs>
                  <circle cx={150} cy={100} r={75} fill="none" />
                  <use xlinkHref="#prefix__a" fill="none" />
                  <text>
                    <textPath xlinkHref="#prefix__a" className="text">
                      {
                        "Vision \u2192 Culture \u2192 Innovation \u2192 Growth \u2192 Value "
                      }
                    </textPath>
                  </text>
                </svg>
              </div>
              <GatsbyImage image={getImage(data.Image)} alt="Stay In The Loop" />
            </Loop>
          </Col>
        </Row>
      </Container>
    </SubscribeWrap>
  );
};

export default Subscribe;
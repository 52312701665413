import React, { useState } from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//styles
import styled from 'styled-components';
import vars from 'components/styles/varss';
import { rem, between } from 'polished';
//components
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//animations
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { HeaderFadeUp, HeaderFadeUp2, HeaderUnderline, Fade } from 'components/animations';

//styled
const OurApproachWrap = styled.section`
  position: relative;
  padding-top: ${rem('300px')};
  background-color: #000;
  .row {
    margin-bottom: ${rem('150px')};
    @media (max-width: ${vars.media.lgMax}) {
      margin-bottom: ${between('100px', '150px')};
    }
  }
`;

const OurApproachHeader = styled.div`
  text-align: center;
  margin-bottom: ${rem('180px')};
  @media (max-width: ${vars.media.lgMax}) {
    margin-bottom: ${between('100px', '180px')};
  }
  h2 {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }
`;

const OurApproachImage = styled.div`
  .gatsby-image-wrapper {
    margin-bottom: ${rem('110px')};
    @media (max-width: ${vars.media.lgMax}) {
      margin-bottom: ${between('60px', '110px')};
    }
  }
`;

const OurApproachText = styled.div`
   p {
    font-size: ${rem('30px')};
    font-weight: 500;
    line-height: 1.33;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${between('24px', '30px')};
    }
    + {
      p {
        margin-top: 35px;
      }
    }
  }
`;

const OurApproachSolutions = styled.div`
   text-align: center;
`;

const OurApproach = () => {

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "flexible-solutions.png" }) {
        childImageSharp {
          gatsbyImageData(width: 752, quality: 100)
        }
      }
      Sphere: file(relativePath: { eq: "solutions-sphere.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100)
        }
      }
    }
  `);

  //animate state
  const [animState, setAnimState] = useState(false);

  const enter = () => {
    setAnimState(true);
  };

  //animate state
  const [animState2, setAnimState2] = useState(false);

  const enter2 = () => {
    setAnimState2(true)
  };

  return (
    <OurApproachWrap>
      <Container>
        <Waypoint onEnter={enter} bottomOffset="20%">
          <OurApproachHeader>
            <motion.div
              initial={false}
              variants={HeaderFadeUp}
              animate={animState ? 'animate' : 'initial'}>
              <Heading as="h2" heading2>We’re an extension of your team, focussed on creating new agents for change and shaping our vision for a more inclusive future of the fashion industry.</Heading>
            </motion.div>
            <div className="overflow">
              <motion.div
                initial={false}
                variants={HeaderFadeUp2}
                animate={animState ? 'animate' : 'initial'}>
                <Heading as="h3" underlined>
                  Our Approach
                  <motion.div
                    className="underline"
                    variants={HeaderUnderline}
                    animate={animState ? 'animate' : 'initial'}
                  />
                </Heading>
              </motion.div>
            </div>
          </OurApproachHeader>
        </Waypoint>
        <Waypoint onEnter={enter2} bottomOffset="20%">
          <motion.div
            initial={false}
            variants={Fade}
            animate={animState2 ? 'animate' : 'initial'}>
            <Row className="row">
              <Col md={7}>
                <OurApproachImage>
                  <GatsbyImage image={getImage(data.Image)} alt="flexible solutions" />
                </OurApproachImage>
                <Heading as="h3" heading2>Flexible<br/> Solutions</Heading>
              </Col>
              <Col md={5}>
                <OurApproachText>
                  <p>We pull together and align the strands of work into a transformational fabric. More than a co-ordination role, we solve your problems, keeping the original end-to-end concept intact, ensuring progress and working with all levels of your organisation.</p>
                  <p>Formed by a filtered international pool of multidisciplinary digital designers, creators and strategists, our work is defined as much by our diverse perspectives as our unity to the same intention; to create remarkable digital experiences that connect people.</p>
                  <p>Our aim is to move away from transactional relationships, in favour of deeper partnerships that bring greater agility and accountability into a hybrid working model. Our focus is on significant, measurable impact. We’re invested in the change we’re looking to create, proud of the work we do and motivated by our clients’ successes.</p>
                </OurApproachText>
              </Col>
            </Row>
            <OurApproachSolutions>
              <Row>
                <Col sm={6} md={3}>
                  <GatsbyImage image={getImage(data.Sphere)} alt="sphere" />
                  <Heading as="h4" heading4>Collaborative<br/> Approach</Heading>
                </Col>
                <Col sm={6} md={3}>
                  <GatsbyImage image={getImage(data.Sphere)} alt="sphere" />
                  <Heading as="h4" heading4>Modular<br/> Implementation</Heading>
                </Col>
                <Col sm={6} md={3}>
                  <GatsbyImage image={getImage(data.Sphere)} alt="sphere" />
                  <Heading as="h4" heading4>Dedicated<br/> Teams</Heading>
                </Col>
                <Col sm={6} md={3}>
                  <GatsbyImage image={getImage(data.Sphere)} alt="sphere" />
                  <Heading as="h4" heading4>Flexible<br /> Billing</Heading>
                </Col>
              </Row>
            </OurApproachSolutions>
          </motion.div>
        </Waypoint>
      </Container>
    </OurApproachWrap>
  );
};

export default OurApproach;
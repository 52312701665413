import React, { useState } from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//styles
import styled from 'styled-components';
import vars from 'components/styles/varss';
import { rem, between } from 'polished';
//components
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//accordion
import Accordion from 'components/ui/accordion';
//animations
import { motion, AnimatePresence } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { HeaderFadeUp, HeaderFadeUp2, HeaderUnderline, Fade } from 'components/animations';

//styled
const OurServicesWrap = styled.section`
  position: relative;
  padding-top: ${rem('300px')};
  background-color: #000;
`;

const OurServicesHeader = styled.div`
  text-align: center;
  margin-bottom: ${rem('230px')};
  @media (max-width: ${vars.media.lgMax}) {
    margin-bottom: ${between('100px', '230px')};
  }
  h2 {
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }
`;

const OurServicesLists = styled.div`
  position: relative;
  @media (max-width: ${vars.media.smMax}) {
    margin-bottom: 60px;
  }
  ul {
    list-style: none;
    padding: 0;
    padding-top: 30px;
    padding-right: 30px;
    margin: 0;
    margin-left: 40%;
    @media (max-width: ${vars.media.smMax}) {
      margin-left: 0;
      padding-top: 10px;
      margin-bottom: 40px;
    }
    li {
      font-size: ${rem('20px')};
      line-height: 1.5;
    }
  }
`;
const OurServicesVideos = styled.div`
  position: relative;
  .sevice-item {
    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

const OurServices = () => {

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "design.png" }) {
        childImageSharp {
          gatsbyImageData(width: 483)
        }
      }
    }
  `);

  //animate state
  const [animState, setAnimState] = useState(false);

  const enter = () => {
    setAnimState(true)
  };

  //animate state
  const [animState2, setAnimState2] = useState(false);

  const enter2 = () => {
    setAnimState2(true)
  };

  //accordion
  const accordionData = [
    {
      id: 1,
      title: 'Design',
      services: [
        'Art Direction',
        'Visual Design',
        'Design Consulting',
        'Brand Strategy',
        'Identity Systems',
        'User Research',
        'Multimedia Displays',
      ]
    },
    {
      id: 2,
      title: 'Digital',
      services: [
        'Lorem ipsum dolor',
        'Sit amet consectetur',
        'Adipisicing elit',
        'Labore illum impedi',
        'Assumenda aperiam',
        'Accusantium repellat',
        'Veniam repellendus',
      ]
    },
    {
      id: 3,
      title: 'Fashion',
      services: [
        'Lorem ipsum dolor',
        'Sit amet consectetur',
        'Adipisicing elit',
        'Labore illum impedi',
        'Assumenda aperiam',
        'Accusantium repellat',
        'Veniam repellendus',
      ]
    },
    {
      id: 4,
      title: 'Marketing',
      services: [
        'Lorem ipsum dolor',
        'Sit amet consectetur',
        'Adipisicing elit',
        'Labore illum impedi',
        'Assumenda aperiam',
        'Accusantium repellat',
        'Veniam repellendus',
      ]
    },
  ];
  const [expanded, setExpanded] = useState(1);

  return (
    <OurServicesWrap>
      <Container>
        <Waypoint onEnter={enter} bottomOffset="20%">
          <OurServicesHeader>
            <motion.div
              initial={false}
              variants={HeaderFadeUp}
              animate={animState ? 'animate' : 'initial'}>
              <Heading as="h2" heading2>We tackle a broad variety of disciplines in the agile process of creating meaningful products</Heading>
            </motion.div>
            <div className="overflow">
              <motion.div
                initial={false}
                variants={HeaderFadeUp2}
                animate={animState ? 'animate' : 'initial'}>
                <Heading as="h3" underlined>
                  Our Services
                  <motion.div
                    className="underline"
                    variants={HeaderUnderline}
                    animate={animState ? 'animate' : 'initial'}
                  />
                </Heading>
              </motion.div>
            </div>
          </OurServicesHeader>
        </Waypoint>
        <Waypoint onEnter={enter2} bottomOffset="20%">
          <motion.div
            initial={false}
            variants={Fade}
            animate={animState2 ? 'animate' : 'initial'}>
            <Row>
              <Col md={6} xlOffset={1} xl={5}>
                <OurServicesLists>
                  {accordionData.map((item) => (
                    <Accordion
                      key={item.id}
                      i={item.id}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      title={item.title}
                      services={item.services}
                    />
                  ))}
                </OurServicesLists>
              </Col>
              <Col md={6} xlOffset={1} xl={5}>
                <OurServicesVideos>
                  <AnimatePresence exitBeforeEnter>
                    {expanded === 1 ? (
                      <motion.div
                        className="sevice-item"
                        key={1}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <GatsbyImage image={getImage(data.Image)} alt="design" />
                      </motion.div>
                    ) : expanded === 2 ? (
                      <motion.div
                        className="sevice-item"
                        key={2}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <img src="https://via.placeholder.com/483x635?text=Image 2" alt="Sample" />
                      </motion.div>
                    ) : expanded === 3 ? (
                      <motion.div
                        className="sevice-item"
                        key={3}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <img src="https://via.placeholder.com/483x635?text=Image 3" alt="Sample" />
                      </motion.div>
                    ) : expanded === 4 ? (
                      <motion.div
                        className="sevice-item"
                        key={4}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <img src="https://via.placeholder.com/483x635?text=Image 4" alt="Sample" />
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                </OurServicesVideos>
              </Col>
            </Row>
          </motion.div>
        </Waypoint>
      </Container>
    </OurServicesWrap>
  );
};

export default OurServices;
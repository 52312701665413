import React from 'react';
//styles
import styled from 'styled-components';
//seo
import Seo from 'components/utils/seo';
//components
import Layout from 'components/ui/layout';
import Footer from "components/ui/footer";
//home components
import HeroText from 'components/home/home-hero-text';
import HeroImage from 'components/home/home-hero-image';
import OurWork from 'components/home/home-our-work';
import Complexity from 'components/home/home-complexity';
import OurSolutions from 'components/home/home-our-solutions';
import OurServices from 'components/home/home-our-services';
import OurApproach from 'components/home/home-our-approach';
import Subscribe from 'components/home/home-subscribe';
import Hello from 'components/home/home-hello';

//styled
const HomeWrap = styled.section`
  position: relative;
`;

const Home = () => {

  return (
    <Layout>
      <Seo />
      <HomeWrap data-scroll-section>
        <HeroText />
        <HeroImage />
        <OurWork />
        <Complexity />
        <OurSolutions />
        <OurServices />
        <OurApproach />
        <Subscribe />
        <Hello />
        <Footer />
      </HomeWrap>
    </Layout>
  );
};

export default Home;
import React, { useState, useEffect } from 'react';
//styles
import styled from 'styled-components';
import { rem, between } from 'polished';
import vars from 'components/styles/varss';
//components
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
//animations
import { motion } from 'framer-motion';
import { HeroTexts, HeroBGAnimate, HeroFadeText  } from 'components/animations';

//styled
const HeroTextWrap = styled.section`
  position: relative;
  padding-top: ${rem('400px')};
  padding-bottom: ${rem('500px')};
  @media (max-width: ${vars.media.lgMax}) {
    padding-top: ${between('200px', '400px')};
    padding-bottom: ${between('300px', '500px')};
  }
  overflow: hidden;
  h1 {
    position: fixed;
    span {
      display: inline-block;
    }
  }
`;

const HeroBG = styled(motion.span)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: #0B0B0B;
  will-change: transition;
`

const HeroText = () => {

  //animate state
  const [animState, setAnimState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimState(true);
    }, 500);
  },[]);

  return (
    <HeroTextWrap>
      <Container>
        <Heading as="h1">
          <motion.div
            initial={false}
            animate={animState ? 'animate' : 'initial'}
            variants={HeroTexts}>
            <motion.span variants={HeroFadeText}>Digital&nbsp;</motion.span>
            <motion.span variants={HeroFadeText}>Fashion&nbsp;</motion.span>
            <motion.span variants={HeroFadeText}>Studio&trade;</motion.span>
          </motion.div>
        </Heading>
        <HeroBG 
          animate={animState ? 'animate' : 'initial'}
          variants={HeroBGAnimate}
         />
      </Container>
    </HeroTextWrap>
  );
};

export default HeroText;
import React, { useState } from 'react';
//styles
import styled from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Container from 'components/ui/container';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//animations
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { Fade } from 'components/animations';

//styled
const FooterWrap = styled.footer`
  position: relative;
  padding-top: ${rem('180px')};
  padding-bottom: ${rem('115px')};
  .row {
    > div {
      @media (max-width: ${vars.media.smMax}) {
        margin-bottom: 60px;
      }
      @media (min-width: ${vars.media.mdMin}) {
        min-height: 270px;
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  h3 {
    font-size: ${rem('20px')};
    color: #999;
    font-weight: normal;
  }
  p {
    line-height: 1.5;
    + p {
      margin-top: 0;
    }
  }
  a {
    color: #fff;
    &:hover {
      color: #999;
    }
  }
  .footer-bottom {
    font-size: ${rem('16px')};
    text-transform: uppercase;
    color: #333;
    @media (min-width: ${vars.media.mdMin}) {
      text-align: right;
    }
  }
`;

const Footer = () => {

  //animate state
  const [animState, setAnimState] = useState(false);

  const enter = () => {
    setAnimState(true)
  };

  return (
    <Waypoint onEnter={enter} bottomOffset="20%">
      <motion.div
        initial={false}
        variants={Fade}
        animate={animState ? 'animate' : 'initial'}>
        <FooterWrap>
          <Container>
            <Row className="row">
              <Col md={3}>
                <h3>Visit</h3>
                <p>Lisbon, PT</p>
                <p>Hong Kong, HK</p>
              </Col>
              <Col md={3}>
                <h3>Connect</h3>
                <p><a href="mailto:info@integral.im">info@integral.im</a></p>
                <p><a href="#/">Telegram</a></p>
              </Col>
              <Col md={3}>
                <h3>Follow</h3>
                <p><a href="#/">Instagram</a></p>
                <p><a href="#/">LinkedIn</a></p>
              </Col>
              <Col md={3}>
                <p>Integral &copy; {new Date().getFullYear()}</p>
              </Col>
            </Row>
            <div className="footer-bottom">
              <p>BEAUTY IS IN ALL THINGS,<br/> IF YOU LOOK CLOSE ENOUGH</p>
            </div>
          </Container>
        </FooterWrap>
      </motion.div>
    </Waypoint>
  );
};

export default Footer;
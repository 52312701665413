//styles
import styled from 'styled-components';
import { between, rem } from 'polished';
import vars from 'components/styles/varss';

//styled
const Button = styled.button`
  display: inline-block;
  border: 1px solid #979797;
  border-radius: 8px;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  line-height: 1;
  color: ${vars.colors.text};
  background-color: transparent;
  min-width: 350px;
  height: 120px;
  font-size: ${rem('30px')};
  @media (max-width: ${vars.media.lgMax}) {
    font-size: ${between('24px', '30px')};
    min-width: ${between('200px', '350px')};
    height: ${between('80px', '120px')};
  }
  span {
    transition: ${vars.transitions.hover1};
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    span {
      transform: translateX(5px);
    }
  }
`;

export default Button;
import React, { useState } from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//styles
import styled from 'styled-components';
import { rem } from 'polished';
//components
import Container from 'components/ui/container';
//framer motion
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { HeroImageFadeUp } from 'components/animations';

//styled
const HeroImageWrap = styled.section`
  position: relative;
  padding-top: ${rem('130px')};
  padding-bottom: ${rem('150px')};
  background-color: #0B0B0B;
  text-align: center;
  .gatsby-image-wrapper {
    display: inline-block;
  }
  .view {
    position: relative;
    border: 1px solid red;
    height: 2px;
    width: 500px;
  }
`;

const HeroImage = () => {

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "jacket.png" }) {
        childImageSharp {
          gatsbyImageData(width: 462, quality: 100)
        }
      }
    }
  `);

  //animate state
  const [animState, setAnimState] = useState(false);

  const enter = () => {
    setAnimState(true)
  };

  return (
    <Waypoint onEnter={enter} bottomOffset="20%">
      <HeroImageWrap id="heroImage" data-scroll-section>
        <Container>
          <motion.div
            initial={false}
            variants={HeroImageFadeUp}
            animate={animState ? 'animate' : 'initial'}>
            <GatsbyImage image={getImage(data.Image)} alt="jacket" />
          </motion.div>
        </Container>
      </HeroImageWrap>
    </Waypoint>
  );
};

export default HeroImage;
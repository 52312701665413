export const HeroTexts = {
  animate: {
    transition: {
      delayChildren: 1.2,
      staggerChildren: 1,
    }
  }
};

export const HeroBGAnimate = {
  initial: {
    y: "0",
  },
  animate: {
    y: "100%",
    transition: {
      ease: [.77, 0, .175, 1],
      duration: 1.5,
    }
  }
};

export const HeroFadeText = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 2,
    }
  }
};

export const HeroImageFadeUp = {
  initial: {
    y: 30,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 1.5,
      delay: 2
    }
  }
}

export const HeaderFadeUp = {
  initial: {
    y: 40,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 1.5,
    }
  }
};

export const HeaderFadeUp2 = {
  initial: {
    y: '100%',
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 1,
      delay: 0.5
    }
  }
};

export const HeaderUnderline = {
  initial: {
    x: '-240%',
  },
  animate: {
    x: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.75,
      delay: 1
    }
  }
};

export const WorkFadeUp = {
  initial: {
    y: 40,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 1.5,
    }
  }
};

export const Fade = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 2,
    }
  }
};

export const ComplexityAnimate = {
  animate: {
    transition: {
      staggerChildren: 0.5,
    }
  }
}
import React, { useState } from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//styles
import styled from 'styled-components';
import vars from 'components/styles/varss';
import { rem, between } from 'polished';
//components
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
import Button from 'components/ui/button';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//animations
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { ComplexityAnimate, Fade } from 'components/animations';

//styled
const ComplexityWrap = styled.section`
  position: relative;
  padding-bottom: ${rem('360px')};
  background-color: #000;
  @media (max-width: ${vars.media.lgMax}) {
    padding-bottom: ${between('200px', '360px')};
  }
`;

const ComplexityTexts = styled.div`
  @media (max-width: ${vars.media.smMax}) {
    margin-bottom: ${rem('80px')};
  }
  p {
    line-height: 1.5;
    strong {
      display: block;
      font-size: ${rem('30px')};
      font-weight: 500;
      line-height: 1.33;
      margin-bottom: 50px;
      @media (max-width: ${vars.media.lgMax}) {
        font-size: ${between('24px', '30px')};
      }
    }
  }
  ${Heading} {
    margin-bottom: 75px;
    @media (max-width: ${vars.media.lgMax}) {
      margin-bottom: ${between('50px', '75px')};
    }
    span {
      display: block;
      &.grey {
        color: #333333;
      }
    }
  }
  ${Button} {
    margin-top: 100px;
    @media (max-width: ${vars.media.lgMax}) {
      margin-top: ${between('50px', '100px')};
    }
  }
`;

const ComplexityImage = styled.div`
  text-align: center;
  .gatsby-image-wrapper {
    display: inline-block;
  }
`;

const Complexity = () => {

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "complexity-simplified.png" }) {
        childImageSharp {
          gatsbyImageData(width: 496, quality: 100)
        }
      }
    }
  `);

  //animate state
  const [animState, setAnimState] = useState(false);

  const enter = () => {
    setAnimState(true)
  };


  return (
    <ComplexityWrap>
      <Container>
        <Waypoint onEnter={enter} bottomOffset="20%">
          <Row className="row">
            <Col order={2} md={6} mdOrder={1}>
              <motion.div initial={false} variants={Fade} animate={animState ? 'animate' : 'initial'}>
                <ComplexityImage>
                  <GatsbyImage image={getImage(data.Image)} alt="Complexity Simplified" />
                </ComplexityImage>
              </motion.div>
            </Col>
            <Col order={1} md={6} mdOrder={2} xlOffset={1} xl={5}>
              <ComplexityTexts>
                <motion.div
                  initial={false}
                  variants={ComplexityAnimate}
                  animate={animState ? 'animate' : 'initial'}>
                  <Heading as="h4" heading2b>
                    <motion.span variants={Fade}>Complexity.</motion.span>
                    <motion.span variants={Fade}>Simplified.</motion.span>
                  </Heading>
                  <motion.div variants={Fade}>
                    <p><strong>Teaming future-forward digital design with impactful sustainable strategies, we empower the next generation of fashion.</strong></p>
                  </motion.div>
                  <motion.div variants={Fade}>
                    <p>We shape modern brands for a connected future by accelerating transformation and visualising fashion in a digital space. Sustainable design thinking is at the core of our decision making and is integral to the impactful products we build with our partners.</p>
                    <p>Our solutions; boost efficiency and profitability, reduce waste and time to market, whilst improving reliability, relevance and flexibility.</p>
                  </motion.div>
                  <motion.div variants={Fade}>
                    <Button>Say Hello <span>→</span></Button>
                  </motion.div>
                </motion.div>
              </ComplexityTexts>
            </Col>
          </Row>
        </Waypoint>
      </Container>
    </ComplexityWrap>
  );
};

export default Complexity;
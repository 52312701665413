import React, { useState } from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//styles
import styled from 'styled-components';
import vars from 'components/styles/varss';
import { rem, between } from 'polished';
//components
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//animations
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';
import { HeaderFadeUp, HeaderFadeUp2, HeaderUnderline, Fade } from 'components/animations';

//styled
const OurSolutionsWrap = styled.section`
  position: relative;
  background-color: #000;
  h2 {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .row {
    @media (min-width: ${vars.media.mdMin}) {
      align-items: flex-end;
    }
    @media (min-width: ${vars.media.xlMin}) {
      > div {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

const OurSolutionsHorizontal = styled.div`
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  ::-webkit-scrollbar-button {
    width: 1px;
    height: 1px;
  }
  .horizontal-item {
    min-width: 100%;
    flex: 1;
  }
`;

const OurSolutionsHeader = styled.div`
  text-align: center;
  margin-bottom: ${rem('230px')};
  @media (max-width: ${vars.media.lgMax}) {
    margin-bottom: ${between('100px', '230px')};
  }
`;

const OurSolutionsTexts = styled.div`
  @media (max-width: ${vars.media.smMax}) {
    margin-bottom: ${between('45px', '90px')};
  }
  h3 {
    text-align: center;
    margin-bottom: ${rem('90px')};
    @media (max-width: ${vars.media.lgMax}) {
      margin-bottom: ${between('45px', '90px')};
    }
  }
  p {
    font-size: ${rem('30px')};
    font-weight: 500;
    line-height: 1.33;
    margin-bottom: ${rem('80px')};
    @media (max-width: ${vars.media.lgMax}) {
      margin-bottom: ${between('40px', '80px')};
      font-size: ${between('24px', '30px')};
    }
  }
  .solutions {
    margin-left: -10px;
    margin-right: -10px;
    > div {
      padding-left: 10px;
      padding-right: 10px;
    }
    ul {
      list-style: none;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
      li {
        line-height: 1.5;
      }
    }
  }
`;

const OurSolutionsImage = styled.div`
  max-width: 483px;
  @media (max-width: ${vars.media.smMax}) {
    max-width: 100%;
    text-align: center;
    .gatsby-image-wrapper {
      display: inline-block;
    }
  }
`;

const OurSolutions = () => {

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "digital-fasion.png" }) {
        childImageSharp {
          gatsbyImageData(width: 483)
        }
      }
    }
  `);

  //animate state
  const [animState, setAnimState] = useState(false);

  const enter = () => {
    setAnimState(true)
  };

  //animate state
  const [animState2, setAnimState2] = useState(false);

  const enter2 = () => {
    setAnimState2(true)
  };


  return (
    <OurSolutionsWrap>
      <Container>
        <Waypoint onEnter={enter} bottomOffset="20%">
          <OurSolutionsHeader>
            <motion.div
              initial={false}
              variants={HeaderFadeUp}
              animate={animState ? 'animate' : 'initial'}>
              <Heading as="h2" heading2>Our innate knowledge of brands,
            allows us to create digital fashion experiences that redefine the boundaries of your business</Heading>
            </motion.div>
            <div className="overflow">
              <motion.div
                initial={false}
                variants={HeaderFadeUp2}
                animate={animState ? 'animate' : 'initial'}>
                <Heading as="h3" underlined>
                  Our Solutions
                  <motion.div
                    className="underline"
                    variants={HeaderUnderline}
                    animate={animState ? 'animate' : 'initial'}
                  />
                </Heading>
              </motion.div>
            </div>
          </OurSolutionsHeader>
        </Waypoint>
      </Container>
      <Waypoint onEnter={enter2} bottomOffset="20%">
        <OurSolutionsHorizontal>
          <motion.div
            initial={false}
            variants={Fade}
            animate={animState2 ? 'animate' : 'initial'}
            className="horizontal-item">
            <Container>
              <Row className="row">
                <Col md={6}>
                  <OurSolutionsTexts>
                    <Heading as="h3" heading2>Digital<br /> Fashion</Heading>
                    <p>Reimagining a digital product design process creates a framework for limitless creative and technical possibilities, diversifying your value chain whilst streamlining workflows.</p>
                    <Row className="solutions">
                      <Col sm={6}>
                        <ul>
                          <li>• Custom avatar creation</li>
                          <li>• 2D pattern design</li>
                          <li>• Modular virtual sampling</li>
                          <li>• Draping & fitting simulation</li>
                          <li>• 3D environment & materials</li>
                        </ul>
                      </Col>
                      <Col sm={6}>
                        <ul>
                          <li>• Collection key look visuals</li>
                          <li>• Animated body dynamics</li>
                          <li>• Virtual fashion runways & 360°</li>
                          <li>• Immersive AR / VR experiences</li>
                          <li>• Blockchain technology integration</li>
                        </ul>
                      </Col>
                    </Row>
                  </OurSolutionsTexts>
                </Col>
                <Col md={6}>
                  <OurSolutionsImage>
                    <GatsbyImage image={getImage(data.Image)} alt="digital fasion" />
                  </OurSolutionsImage>
                </Col>
              </Row>
            </Container>
          </motion.div>
          <div className="horizontal-item">
            <Container>
              <Row className="row">
                <Col md={6}>
                  <OurSolutionsTexts>
                    <Heading as="h3" heading2>Virtual<br /> Showrooms</Heading>
                    <p>Merging the digital & physical worlds, virtual showrooms allow collections to be shared globally, increasing sales and contributing to effective, visually led communications.</p>
                    <Row className="solutions">
                      <Col sm={6}>
                        <ul>
                          <li>• Custom avatar creation</li>
                          <li>• 2D pattern design</li>
                          <li>• Modular virtual sampling</li>
                          <li>• Draping & fitting simulation</li>
                          <li>• 3D environment & materials</li>
                        </ul>
                      </Col>
                      <Col sm={6}>
                        <ul>
                          <li>• Collection key look visuals</li>
                          <li>• Animated body dynamics</li>
                          <li>• Virtual fashion runways & 360°</li>
                          <li>• Immersive AR / VR experiences</li>
                          <li>• Blockchain technology integration</li>
                        </ul>
                      </Col>
                    </Row>
                  </OurSolutionsTexts>
                </Col>
                <Col md={6}>
                  <OurSolutionsImage>
                    <img src="https://via.placeholder.com/483x496?text=Image 2" alt="Sample" />
                  </OurSolutionsImage>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="horizontal-item">
            <Container>
             <Row className="row">
                <Col md={6}>
                  <OurSolutionsTexts>
                    <Heading as="h3" heading2>Brand<br /> Materials</Heading>
                    <p>Merging the digital & physical worlds, virtual showrooms allow collections to be shared globally, increasing sales and contributing to effective, visually led communications.</p>
                    <Row className="solutions">
                      <Col sm={6}>
                        <ul>
                          <li>• Custom avatar creation</li>
                          <li>• 2D pattern design</li>
                          <li>• Modular virtual sampling</li>
                          <li>• Draping & fitting simulation</li>
                          <li>• 3D environment & materials</li>
                        </ul>
                      </Col>
                      <Col sm={6}>
                        <ul>
                          <li>• Collection key look visuals</li>
                          <li>• Animated body dynamics</li>
                          <li>• Virtual fashion runways & 360°</li>
                          <li>• Immersive AR / VR experiences</li>
                          <li>• Blockchain technology integration</li>
                        </ul>
                      </Col>
                    </Row>
                  </OurSolutionsTexts>
                </Col>
                <Col md={6}>
                  <OurSolutionsImage>
                    <img src="https://via.placeholder.com/483x496?text=Image 3" alt="Sample" />
                  </OurSolutionsImage>
                </Col>
              </Row>
            </Container>
          </div>
        </OurSolutionsHorizontal>
      </Waypoint>
    </OurSolutionsWrap>
  );
};

export default OurSolutions;